<template>  
    <div>
        <h4 class="mb-3">
            Tableau de bord
        </h4>

        <p style="text-align: justify;">
            Vous pouvez voir l'état de la facturation coté Cloud Provider et ainsi suivre votre consommation. 
            Il est possible de configurer une limite budgétaire qui permettra d'arrêter toutes les machines si jamais elle est atteinte.
            Vous pouvez aussi voir les différentes statistiques de la plateforme.
        </p>


        <!-- If loaded -->
        <div v-if="loaded">

            <!-- Stats of the platform -->
            <h5 class="mb-3">Statistiques</h5>
            <div class="row">
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Users</h6>
                            <p class="lead text-center mb-0">
                                <span v-if="stats.users !== undefined">
                                    <span title="En ligne">{{stats.usersOnline}}</span>/<span title="Total">{{stats.users}}</span>
                                    <i class="bi bi-person ms-2"></i>
                                    <!-- <small><em>{{stats.usersOnline}}/{{stats.users-stats.usersOnline}}</em></small> -->
                                </span>
                                <span v-else class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Groups</h6>
                            <p class="lead text-center mb-0">
                                <span v-if="stats.groups !== undefined">{{stats.groups}}<i class="bi bi-people ms-2"></i></span>
                                <span v-else class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Infrastructures</h6>
                            <p class="lead text-center mb-0">
                                <span v-if="stats.infras !== undefined">
                                    <span title="En fonctionnement">{{stats.infrasRunning}}</span>/<span title="Total">{{stats.infras}}</span>
                                    <i class="bi bi-building ms-2"></i>
                                </span>
                                <span v-else class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Instances</h6>
                            <p class="lead text-center mb-0">
                                <span v-if="stats.instances !== undefined">
                                    <span title="En fonctionnement">{{stats.instancesRunning}}</span>/<span title="Total">{{stats.instances}}</span>
                                    <i class="bi bi-pc-display ms-2"></i>
                                </span>
                                <span v-else class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Stockage (To)</h6>
                            <p class="lead text-center mb-0">
                                <span v-if="stats.storage !== undefined">{{(stats.storage/1024).toFixed(2)}} <i class="bi bi-device-hdd ms-2"></i></span>
                                <span v-else class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

                        <!-- Stats of the platform -->
            <h5 class="mb-3">Limites du compte Cloud</h5>
            <div class="row">

                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Cores</h6>
                            <p class="lead text-center mb-0">
                                <span v-if="stats.cloudLimitsCores !== undefined">
                                    <span>{{stats.cloudLimitsCores}}</span>
                                    <i class="bi bi-cpu ms-2"></i>
                                </span>
                                <span v-else class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Vpcs</h6>
                            <p class="lead text-center mb-0">
                                <span v-if="stats.cloudLimitsVpc !== undefined">
                                    <span>{{stats.cloudLimitsVpc}}</span>
                                    <i class="bi bi-diagram-3 ms-2"></i>
                                </span>
                                <span v-else class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Eips</h6>
                            <p class="lead text-center mb-0">
                                <span v-if="stats.cloudLimitsEip !== undefined">
                                    <span>{{stats.cloudLimitsEip}}</span>
                                    <i class="bi bi-geo-alt ms-2"></i>
                                </span>
                                <span v-else class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <!-- Billing information -->
            <h5 class="mb-3">Facturation</h5>
            <div class="alert alert-light">
                <h6>Notes à propos du 'Forecast'</h6>
                <p style="text-align: justify;">
                    Le forecasting se base sur les données des journées passées et fait des estimations au plus haut.
                    Il permet de se projeter, il est donc intéréssant de le surveiller après la première quinzaine du mois courant.
                    Pour résumer, plus il y aura de journées réalisées, plus cet indicateur sera fiable.
                </p>
            </div>
            <div class="row mb-3" v-if="!noUsages">

                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Total pour le mois</h6>
                            <span class="lead" v-if="usage !== null">
                                {{usage}} $
                                <!-- <small>(+ 10.00%)</small> -->
                            </span>
                            <p class="text-center mb-0" v-else>
                                <span class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
                
                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Forecast pour le mois</h6>
                            <span class="lead" v-if="forecast !== null">{{forecast}} $</span>
                            <p class="text-center mb-0" v-else>
                                <span class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">Budget restant pour le mois</h6>
                            <span class="lead" v-if="usage !== null">~{{maxBudget - usage}} $</span>
                            <p class="text-center mb-0" v-else>
                                <span class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row" v-if="!noUsages">
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Consommation sur les 7 derniers jours</h6>
                            <table class="table table-sm table-striped table-hover" v-if="lastDays.length > 0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th class="text-end">Montant facturé</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(day,index) in lastDays" :key="day.TimePeriod.Start">
                                        <td style="wrap">{{ (new Date(day.TimePeriod.End)).toLocaleDateString() }}</td>
                                        <td class="w-100 text-end">
                                            <span v-if="!lastDays[index+1]"></span>
                                            <small class="text-success" v-if="lastDays[index+1] && lastDays[index+1].Total.UnblendedCost.Amount > day.Total.UnblendedCost.Amount">
                                                <i class="bi bi-dash"></i>{{ Number.parseFloat( 100 - ((day.Total.UnblendedCost.Amount/lastDays[index+1].Total.UnblendedCost.Amount)*100) ).toFixed(2) }}%
                                                <i class="bi bi-caret-down-fill me-2"></i>
                                            </small>
                                            <small class="text-danger" v-if="lastDays[index+1] && lastDays[index+1].Total.UnblendedCost.Amount < day.Total.UnblendedCost.Amount">
                                                <i class="bi bi-plus"></i>{{ Number.parseFloat( ((day.Total.UnblendedCost.Amount/lastDays[index+1].Total.UnblendedCost.Amount)*100) - 100 ).toFixed(2) }}%
                                                <i class="bi bi-caret-up-fill me-2"></i>
                                            </small>
                                            {{day.Total.UnblendedCost.Amount}} $
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="text-center mb-0" v-else>
                                <span class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="!noUsages">
                <div class="col">
                    <div class="card mb-3">
                        <div class="card-body">
                            <h6 class="card-title">Les 5 dernières factures</h6>
                            <table class="table table-sm table-striped table-hover" v-if="lastInvoices.length > 0">
                                <thead>
                                    <tr>
                                        <th class="w-100">Période facturée</th>
                                        <th class="text-end">Montant total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(invoice,index) in lastInvoices" :key="invoice.TimePeriod.Start">
                                        <td style="w-100">{{ monthsInFrench[(new Date(invoice.TimePeriod.Start)).getMonth()] }} {{ (new Date(invoice.TimePeriod.End)).getFullYear() }}</td>
                                        <td class="w-100 text-end">
                                            <span v-if="!lastInvoices[index+1]"></span>
                                            <small class="text-success" v-if="lastInvoices[index+1] && lastInvoices[index+1].Total.UnblendedCost.Amount > invoice.Total.UnblendedCost.Amount">
                                                <i class="bi bi-dash"></i>{{ Number.parseFloat( 100 - ((invoice.Total.UnblendedCost.Amount/lastInvoices[index+1].Total.UnblendedCost.Amount)*100) ).toFixed(2) }}%
                                                <i class="bi bi-caret-down-fill me-2"></i>
                                            </small>
                                            <small class="text-danger" v-if="lastInvoices[index+1] && lastInvoices[index+1].Total.UnblendedCost.Amount < invoice.Total.UnblendedCost.Amount">
                                                <i class="bi bi-plus"></i>{{ Number.parseFloat( ((invoice.Total.UnblendedCost.Amount/lastInvoices[index+1].Total.UnblendedCost.Amount)*100) - 100 ).toFixed(2) }}%
                                                <i class="bi bi-caret-up-fill me-2"></i>
                                            </small>
                                            {{invoice.Total.UnblendedCost.Amount}} $
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="text-center mb-0" v-else>
                                <span class="spinner spinner-border text-muted"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- No usages -->
            <div v-if="noUsages">
                <div class="alert alert-warning">
                    <h6><i class="bi bi-exclamation-triangle me-2"></i>Pas de données</h6>
                    <p class="mb-0">
                        Il n y a pas encore de données de facturation. Veuillez attendre que des données soient générées.
                    </p>
                </div>
            </div>
            
        </div>
        <!-- Not loaded -->
        <div v-else>
            <span class="spinner spinner-border spinner-border-sm me-2"></span> {{ (loadMessage) ? loadMessage : "Chargement..." }}
        </div>
        

    </div>
</template>

<style scoped>

    table tbody tr td{
        white-space: nowrap;
    }

</style>

<script setup>

    import {onMounted, ref, toRefs, defineEmits, defineProps, computed} from "vue"
    import axios from "axios";

    let props = defineProps(["userInfo"]);

    // To manager the loading page
    let loaded = ref(false);
    let noUsages = ref(false);
    let loadMessage = ref("");

    // Local vars
    let usage = ref(null);
    let maxBudget = ref(0);
    let forecast  = ref(null);
    let lastDays = ref([]);
    let lastInvoices = ref([]);
    let monthsInFrench = ref([
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ]);
    let stats = ref({});

    onMounted(() => {
        load()
    })

    async function load(){
        loadMessage.value = "Chargement des coûts totaux...";

        axios.get("/api/costs/stats")
            .then( answer => {
                stats.value = answer.data;
                return axios.get("/api/costs/");
            })
            .then( answer => {
                
                let dailyUsage = answer.data.dailyUsage;
                let forecastUsage = answer.data.forecastUsage;
                let lastInvoicesUsage = answer.data.lastInvoices;

                // Max budget
                maxBudget.value = answer.data.maxBudget;

                // Month usage
                usage.value =  Number.parseFloat(dailyUsage.data.reduce((previous,current) => {
                    return previous+Number.parseFloat(current.Total.UnblendedCost.Amount)
                }, 0)).toFixed(2);

                // Forecast usage
                forecast.value = Number.parseFloat(forecastUsage.data[0].MeanValue).toFixed(2);

                // Last 7 days usage
                lastDays.value = dailyUsage.data.reverse().filter( day => (new Date(day.TimePeriod.End)).getTime() < (new Date()).getTime() ).slice(0,7);

                // Last 5 invoices
                lastInvoices.value = lastInvoicesUsage.data.reverse();

            })
            .catch( answer => {
                noUsages.value = true;
                console.log(answer);
            })

        loaded.value = true;

    }

</script>